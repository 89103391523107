.bsa-button
    width: 100%
    height: 45px
    background: $bsa-dark-blue
    color: white
    border: none
    border-radius: 6px
    cursor: pointer
    text-transform: capitalize
    transition: background 0.3s

    &:disabled
        background: $bsa-light-gray
        cursor: not-allowed

.bsa-white-blue-button
    width: 100%
    height: 45px
    background: $bsa-white-blue
    color: white
    border: none
    border-radius: 8px
    cursor: pointer
    font-weight: 700

    &:disabled
        background: $bsa-light-gray
        cursor: not-allowed

.bsa-white-button
    width: 100%
    height: 45px
    background: white
    color: $bsa-white-blue
    border: 1px solid $bsa-white-blue
    border-radius: 8px
    cursor: pointer
    font-weight: 700

    &:focus
        outline: none
    
    &:disabled
        background: $bsa-light-gray
        cursor: not-allowed

.bsa-dark-blue-border-button
    width: 100%
    height: 45px
    background: white
    color: $bsa-dark-blue
    border: 1px solid $bsa-dark-blue
    border-radius: 8px
    cursor: pointer
    font-weight: 700

    &:focus
        outline: none

.informative-modal
    min-width: calc( 100vw - 24px )

.upload-modal
    min-width: 730px
    max-width: 730px

@media (min-width: 1024px)
    .informative-modal
        min-width: 500px
        max-width: 500px !important

input
    &:focus
        outline: none

    &:-webkit-autofill
        background: white
        -webkit-box-shadow: 0 0 0px 1000px white inset !important

.background-dark-blue
    background: $bsa-dark-blue

.background-blue
    background: $bsa-blue

.gray-text
    color: $bsa-gray-text

.light-gray-text
    color: $bsa-light-gray

.dark-blue-text
    color: $bsa-dark-blue

.dark-gray-text
    color: $bsa-dark-gray

.white-blue-text
    color: $bsa-white-blue

.blue-text
    color: $bsa-blue

.gray-no-select
    color: $bsa-gray-no-select

.italic
    font-style: italic

.link
    color: $bsa-white-blue
    text-decoration: underline $bsa-white-blue
    cursor: pointer

.m-0
    margin: 0

.small-text
    font-size: 12px

.bold
    font-weight: 700

.bsa-white-gray-button
    width: 100%
    height: 45px
    background: $bsa-white-blue
    color: white
    border: none
    border-radius: 8px
    cursor: pointer
    font-weight: 700

    &:disabled
        background: $bsa-light-gray
        cursor: not-allowed