$bsa-snow-blue: #d0e5fe
$bsa-dark-blue: #0033a1
$bsa-blue: #3154a5
$bsa-white-blue: #007BFF
$bsa-gray-text: #66737F
$bsa-dark-gray: #495057
$bsa-light-gray: #B8CBD0
$bsa-red: #B71C1C
$bsa-green: #2E7D32
$bsa-gray-no-select: #A9ABAC
